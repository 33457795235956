<template>
  <div
    :class="['home-main-container', {'is-not-login': !isLoginUser}]"
  >
    <div v-if="isHomePage()">
      <div
        v-if="showDiscoverOffer"
        class="discover-offer-container"
      >
        <div class="discover-offer-title">
          {{ $t('homeDiscoverOffer') }}
        </div>
        <a target="_blank" :href="discoverHref" class="discover-offer-content">
          {{ $t('homeBrowseOffer') }}
        </a>
      </div>
      <div
        v-if="isLoginUser && myWalletSectionLength"
        class="home-section-header"
      >
        <div
          class="home-section-label"
        >
          {{ $t('homeMyWallet') }}
        </div>
        <a
          class="home-section-link"
          data-aid="home_my_wallet_more"
          @click="pushRouterWithQuery($router, { path:'my-wallet-all' })"
        >
          {{ $t('homeShowMore') }}
        </a>
      </div>

      <div v-if="isDebug" class="carousel-container">
        <agile-banner
          class="carousel-item"
          :banners="banners"
          :height="isMobile?132:150"
          :width="isMobile?343:964"
        />
      </div>

      <div class="home-coupon-container">
        <div
          class="coupon-list-container"
        >
          <transition-group
            v-if="isLoginUser && myWalletSectionLength"
            name="fade"
            class="coupon-list"
            :style="couponListStyle"
          >
            <coupon-loading-item
              v-for="index in 8"
              v-show="$store.getters['app/getIsCouponItemLoading']"
              :key="`coupon-${index}-loading`"
              class="home-coupon-item"
            />
            <vertical-banner
              v-for="banner in homeMyWalletBanners"
              :key="`home-my-wallet-banner-${banner.id}`"
              class="coupon-item selectable-card is-available"
              :width="isMobile?'225px':'240px'"
              :banner="banner"
            />
            <coupon-item
              v-for="coupon in coupons"
              v-show="!coupon.access_campaign_copy_url"
              :key="`coupon-${coupon.id}`"
              class="home-coupon-item"
              :item="coupon"
              :is-coupon="true"
              :is-campaign="false"
            />

          </transition-group>
        </div>

        <div
          key="left-arrow"
          class="arrow-btn"
          :class="['arrow-btn','left', {active: showLeftArrow}]"
          @click="moveLeft"
        >
          <img
            class="arrow-icon"
            srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
            src="/img/arrow-icon.2x.png"
          >
        </div>
        <div
          key="right-arrow"
          :class="['arrow-btn','right', {active: showRightArrow}]"
          @click="moveRight"
        >
          <img
            class="arrow-icon"
            srcset="/img/arrow-icon.1x.png, /img/arrow-icon.2x.png 2x, /img/arrow-icon.3x.png 3x"
            src="/img/arrow-icon.2x.png"
          >
        </div>
      </div>

      <div
        v-if="isLoginUser && goodieBagRecord"
        class="home-section-header"
      >
        <div
          class="home-section-label"
        >
          {{ $t('myWalletMyVouchers') }}
        </div>
        <a
          class="home-section-link"
          data-aid="home_my_rewards_more"
          @click="pushRouterWithQuery($router, { path:'my-reward' })"
        >
          {{ $t('homeShowMore') }}
        </a>
      </div>
      <div
        v-if="goodieBagRecord"
        class="goodie-bag-container"
      >
        <goodie-bag-list-item
          :item="goodieBagRecord"
          @showTnc="toggleTncDialog"
        />
      </div>
    </div>
    <div v-if="isWechatMiniProgram && isExplorePage()">
      <div
        class="home-section-header explore-more"
      >
        <div
          class="home-section-label"
        >
          {{ $t('homeExploreMore') }}
        </div>
      </div>

      <campaign-list-search-bar
        :class="$store.getters['app/isMobile']? 'mobile': ''"
        :goodiebag-name="$t('homeTopOfferTitle')"
        @setsearchkey="searchKey = $event"
      />

      <div
        :class="['filter-container', {'filter-bar-shown': filterSideBarShown, 'hasCategoryCarousel': hasCategoryCarousel}]"
      >
        <sort-filter-bar
          ref="filter"
          v-model="filters"
          :filter-name="filterName"
          :filter-options="filterOptions"
          :filter-result-count="filterResultCount"
          :campaign-count="resultCount"
          :custom-filter-option-labels="customFilterOptionLabels"
          :title-custom-refs="titleCustomRefs"
          :campaign-settings="campaignSettings"
          :locations="locations"
          :number-of-visualize-threads="numberOfVisualizeThreads"
          :search-key="searchKey"
          :in-filtering="isCampaignUpdating"
          @sub-menu-changed="filterSubMenuChanged"
          @toggle-sidebar="toggleFilterSideBar"
          @is-show-visualize-filter="isShowVisualizeFilter"
          @visualize-changed="visualizeChanged"
          @input="inputChanged"
        />
      </div>

      <!--<div
        v-if="isWechatMiniProgram"
        class="result-count"
      >
        {{ $t('goodiebagResults', {count: resultCount}) }}
      </div>-->

      <transition-group
        name="fade"
        class="campaign-container"
      >
        <coupon-loading-item
          v-for="index in 8"
          v-show="$store.getters['app/getIsCouponItemLoading']"
          :key="`campaign-${index}-loading`"
        />
        <coupon-item
          v-for="campaign in campaigns"
          v-show="!campaign.access_campaign_copy_url"
          :key="`campaign-${campaign.id}`"
          :item="campaign"
          :campaign-settings="campaignSettings"
          :is-campaign="true"
          :search-key="searchKey"
        />

        <div
          v-if="(!campaigns || campaigns.length === 0) && !$store.getters['app/getIsCouponItemLoading'] && !isCampaignUpdating"
          key="empty-result"
          class="empty-result"
        >
          <search-campaign-no-result v-if="searchKey" />
          <div
            v-else
            class="empty-result-title"
          >
            {{ $t('goodiebagNoResultsFound') }}
          </div>
        </div>
      </transition-group>
    </div>
    <div v-if="isHomePage()">
      <transition name="el-zoom-in-bottom">
        <div
          v-if="isShowAddToHomeScreen || isShowCookiePolicy"
          class="bottom-alert"
        >
          <add-to-home-screen
            v-if="isShowAddToHomeScreen && mobileType!='PC'"
            @close="addToHomeScreenClose"
          />
          <cookie-policy
            v-if="isShowCookiePolicy"
            @close="cookiePolicyClose"
          />
        </div>
      </transition>

      <template v-if="(campaigns && campaigns.length)">
        <div
          class="home-section-header"
        >
          <div
            class="home-section-label"
          >
            {{ $t('homeTopOfferTitle') }}
          </div>
          <a
            class="home-section-link"
            data-aid="home_my_wallet_more"
            @click="pushRouterWithQuery($router, { path:'explore' })"
          >
            {{ $t('homeShowMore') }}
          </a>
        </div>

        <transition-group
          name="fade"
          class="campaign-container"
        >
          <coupon-loading-item
            v-for="index in 8"
            v-show="$store.getters['app/getIsCouponItemLoading']"
            :key="`campaign-${index}-loading`"
          />
          <coupon-item
            v-for="campaign in topOfferCampaigns"
            v-show="!campaign.access_campaign_copy_url"
            :key="`campaign-${campaign.id}`"
            :item="campaign"
            :campaign-settings="campaignSettings"
            :is-campaign="true"
          />

          <div
            v-if="(!campaigns || campaigns.length === 0) && !$store.getters['app/getIsCouponItemLoading'] && !isCampaignUpdating"
            key="empty-result"
            class="empty-result"
          >
            <div
              class="empty-result-title"
            >
              {{ $t('goodiebagNoResultsFound') }}
            </div>
          </div>
        </transition-group>

        <div class="view-all-offers-button-container">
          <div
            class="view-all-offers-button"
            @click="pushRouterWithQuery($router, { path:'explore' })"
          >
            <div class="view-all-offers-text">
              {{ $t('homeTopOfferViewMore') }}
            </div>
          </div>
        </div>
      </template>

      <div class="carousel-container">
        <agile-banner
          class="carousel-item"
          :banners="banners"
          :height="isMobile?132:150"
          :width="isMobile?343:964"
        />
      </div>
      <terms-dialog
        v-if="tncItem"
        v-model="showTnc"
        :title="tncItem.goodie_bag.generic_terms_and_conditions_title"
        :content="tncItem.goodie_bag.generic_terms_and_conditions_content"
      />
    </div>
  </div>
</template>
<script>
import vue from 'vue'
import VueCookie from 'vue-cookie'
import AddToHomeScreen from '@/components/AddToHomeScreen.vue'
import CouponLoadingItem from '@/components/CouponLoadingItem.vue'
import CookiePolicy from '@/components/CookiePolicy.vue'
import CouponItem from '@/components/CouponItem'
import SortFilterBar from '@/components/SortFilterBar'
import GoodieBagListItem from '@/components/GoodieBagListItem'
import axios from 'axios'
import { getChannelName, convertToQueryString } from '@/utils/utils'
import agileBanner from '@/components/AgileBanner'
import VerticalBanner from '@/components/VerticalBanner'
import TermsDialog from '@/components/TermsDialog'
import CampaignListSearchBar from '@/components/CampaignListSearchBar'
import SearchCampaignNoResult from '@/components/SearchCampaignNoResult'
import { isWechatMiniProgram } from '@/utils/utils'
import { fetchCampaigns } from '@/helpers/http'
import utilsMixin from '@/mixins/utilsMixin'
import sortFilterMixin from '@/mixins/sortFilterMixin'
import { sendPageViewAnalytics, sendAdobePageViewAnalytics, sendAdobeManualAnalytics } from '@/utils/tracking'

import backHandlerMixin from '@/mixins/backHandlerMixin'
import stringify from 'json-stable-stringify'
// import { LOCATION_DISTRICT_ID } from '../utils/utils'
// import { LOCATION_AREA_ID, LOCATION_DISTRICT_ID } from '../utils/utils'
// import { LOCATION_DISTRICT_ID, LOCATION_AREA_ID } from '../utils/utils'

vue.use(VueCookie)

export default {
  components: {
    CouponItem,
    CookiePolicy,
    AddToHomeScreen,
    CouponLoadingItem,
    SortFilterBar,
    GoodieBagListItem,
    TermsDialog,
    agileBanner,
    VerticalBanner,
    CampaignListSearchBar,
    SearchCampaignNoResult
  },
  mixins: [utilsMixin, backHandlerMixin, sortFilterMixin],
  data: function () {
    return {
      filters: {
        sorting: 'featured'
      },
      scrollIndex: 0,
      filterName: 'homepage-filter',
      filterValue: null,
      filterSubMenu: null,
      isShowAddToHomeScreen: false,
      isShowCookiePolicy: false,
      brandOptions: [],
      offerTypeOptions: [],
      isCampaignUpdating: true,
      filterResultCount: {},
      isEndReached: false,
      resultCount: null,
      cancelTokenSource: null,
      filterCancelTokenSource: null,
      pageSize: 10,
      tncItemId: 0,
      filterSideBarShown: false,
      showTnc: false,

      customFilterOptionLabels: [],
      titleCustomRefs: {},
      customAttributeTags: {},

      campaignSettings: {},
      hasCategoryCarousel: true,
      numberOfVisualizeThreads: 0,
      // cachedLocationResponse: null,
      cachedResponse: {},
      cachedQuery: {},

      searchKey: ''
    }
  },
  computed: {
    isDebug () {
      const urlParams = new URLSearchParams(window.location.search)
      return !!urlParams.get('debug')
    },
    showDiscoverOffer () {
      const isLoading = this.$store.getters['app/getIsCouponItemLoading']
      const noCoupon = !this.myWalletSectionLength
      return !isLoading && !this.isWechatMiniProgram && this.isLoginUser && noCoupon && !this.goodieBagRecord
    },
    isLoginUser () {
      return localStorage.getItem('refreshToken')
    },
    showLeftArrow () {
      return this.scrollIndex > 0
    },
    showRightArrow () {
      return this.myWalletSectionLength - this.scrollIndex > 3
    },
    isWechatMiniProgram () {
      return isWechatMiniProgram()
    },
    pageNumber () {
      return parseInt(this.campaigns.length / this.pageSize, 10) + 1
    },
    categories () {
      return this.$store.getters['campaigns/categories']
    },
    offerTypeLabels () {
      return this.$store.getters['campaigns/offerTypeLabels']
    },
    brands () {
      return this.$store.getters['campaigns/brands']
    },

    mobileType () {
      return this.judgeClient()
    },
    isMobile () {
      return this.$store.getters['app/isMobile']
    },
    couponListStyle () {
      let offset = this.scrollIndex * 260
      if (this.myWalletSectionLength - this.scrollIndex === 3) {
        offset -= 204
      }

      return { transform: `translateX(-${offset}px)` }
    },
    filterOptions () {
      let sorting = [
        { label: this.$t('goodiebagFeatured'), value: 'featured' },
        { label: this.$t('campaignStarredCampaign'), value: '-is_starred' },
        { label: this.$t('goodiebagAlphabetical(aToz)'), value: 'name', shortLabel: this.$t('goodiebagAlpha...(aToz)') },
        { label: this.$t('goodiebagAlphabetical(zToa)'), value: '-name', shortLabel: this.$t('goodiebagAlpha...(zToa)') }
      ]
      if (this.searchKey) {
        sorting = [{ label: this.$t('goodiebagRecommend'), value: 'recommended' }].concat(sorting)
      }

      const categoryHideIds = this.getCategoryHideIds()
      const categoryOptions = this.categories
        .filter(item => !['', null, undefined].includes(item.name) && !categoryHideIds.includes(item.id))
      const brandOptions = this.brands
        .filter(item => !['', null, undefined].includes(item.name))
        .map(item => item.name)
      const offerTypeOptions = this.offerTypeLabels
        .filter(item => !['', null, undefined].includes(item.name))
        .map(item => item.name)
      let locationOptions = this.locations
        .filter(item => !['', null, undefined].includes(item.name))
      locationOptions = this.convertLocationFilterOptions(locationOptions)

      const brandIdMaps = {}
      this.brands
        .filter(item => !['', null, undefined].includes(item.name))
        .forEach(item => {
          brandIdMaps[item.name] = item.id
        })

      const offerTypeLabelIdMaps = {}
      this.offerTypeLabels
        .filter(item => !['', null, undefined].includes(item.name))
        .forEach(item => {
          offerTypeLabelIdMaps[item.name] = item.id
        })

      const customOptions = this.customAttributeTags

      // console.log('@340', customOptions)
      // console.log('@340b', brandOptions)
      return {
        sorting: sorting,
        searchKey: this.searchKey,
        category: [...new Set(categoryOptions)],
        brand: [...new Set(brandOptions)],
        offerType: [...new Set(offerTypeOptions)],
        location: [...new Set(locationOptions)],
        customOptions,
        brandIdMaps,
        offerTypeLabelIdMaps
      }
    },
    tncItem () {
      if (!this.goodieBagRecord) {
        return null
      }
      return this.tncItemId === this.goodieBagRecord.id ? this.goodieBagRecord : null
    },
    banners () {
      return this.$store.getters['app/getHomeBanners']
    },
    homeMyWalletBanners () {
      const banners = this.$store.getters['app/getHomeMyWalletBanners']
      return banners
    },
    coupons () {
      const couponTypes = ['COUPON_SECTION', 'VOUCHER_SECTION', 'TICKET_SECTION']
      const items = this.$store.getters['coupons/couponList']('All status', couponTypes)
      return items.slice(0, 8)
    },
    myWalletSectionLength () {
      const couponsLength = this.coupons && this.coupons.length || 0
      const homeMyWalletBannersLength = this.homeMyWalletBanners && this.homeMyWalletBanners.length || 0
      return couponsLength + homeMyWalletBannersLength
    },
    goodieBagRecord () {
      const { 0: goodieBagRecord } = this.$store.getters['goodiebagparticipationrecords/recordList'](this.$store.getters['auth/isTestUser'])
      return goodieBagRecord
    },
    campaigns () {
      return this.$store.getters['campaigns/campaignList']
    },
    topOfferCampaigns () {
      return this.$store.getters['campaigns/topOfferCampaginList']
    },
    discoverHref () {
      const lang = this.$i18n.locale
      if (lang === 'en') {
        return `${process.env.VUE_APP_DEALS_EN_URL}`
      }
      return `${process.env.VUE_APP_DEALS_TC_SC_URL}`
    },
    locations () {
      return this.$store.getters['campaigns/locations']
    }
  },
  watch: {
    'isMobile': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.scrollIndex = 0
      }
    },
    'filters': function (newVal, oldVal) {
      console.log('@486', this.isBackNavigation)
      if (stringify(newVal) !== stringify(oldVal) && !this.isBackNavigation) {
        console.log('@484 -123', oldVal, '\n', newVal)
        console.log('@484', JSON.stringify(oldVal), '\n', JSON.stringify(newVal))
        this.clearCampaigns()
        this.isEndReached = false
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel()
        }
        this.getCampaigns(false, true)
      }
    },
    'searchKey': function (newVal, oldVal) {
      console.log('??? set to store', newVal)
      this.$store.commit('app/setlastCampaignListSearchKeyState', newVal)
      if (newVal !== oldVal) {
        if (newVal) {
          this.filters.sorting = 'recommended'
        } else {
          this.filters.sorting = 'featured'
        }
        this.filters.searchKey = newVal
        this.clearCampaigns()
        this.isEndReached = false
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel()
        }
        this.getCampaigns(true)
        let filterValue = this.$store.getters['app/getFilterValueAll']
        filterValue = { ...this.filterValue, searchKey: newVal }
        this.updateAllSubmenus(filterValue)
      }
    }
  },

  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, true)
    this.$store.commit('app/setNavIsHomePage', false)
    // this.$store.commit('app/setNeedToShowAlert', false)
    console.log('@512 545', this.resultCount, this.isEndReached, this.isCampaignUpdating)
    this.$store.commit('app/setlastCampaignListState',
      { resultCount: this.resultCount,
        isEndReached: this.isEndReached,
        isCampaignUpdating: this.isCampaignUpdating,
        titleCustomRefs: this.titleCustomRefs,
        customFilterOptionLabels: this.customFilterOptionLabels,
        customAttributeTags: this.customAttributeTags,
        campaignSettings: this.campaignSettings
        // searchKey: this.searchKey
      }
    )
  },
  beforeRouteLeave (to, from, next) {
    // cannot put in beforeRouteEnter, otherwise 'this' is not available
    console.log('@499b, route enter', from.name, to.name)

    // ...
    // this.$store.commit('app/setNeedShowCookieButton', false)
    if (this.isWechatMiniProgram) {
      if ((this.isHomePage(from.name) && this.isExplorePage(to.name)) ||
      (this.isExplorePage(from.name) && this.isHomePage(to.name))) {
        this.isEndReached = false
        this.isCampaignUpdating = false

        console.log('@499b2')

        this.clearCampaigns()

        if (this.isExplorePage(to.name)) {
          this.getCampaigns()
        } else {
          this.getTopOfferCampaigns()
        }
      }
    }

    if (from.name === 'home') {
      this.$store.commit('app/setMiniProgramBackTitle', this.$t('toHome'))
    } else if (from.name === 'explore') {
      this.$store.commit('app/setMiniProgramBackTitle', this.searchKey ? this.$t('toSearchResult') : this.$t('toDeals'))
    }

    next()
  },
  async mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
    if (this.isWechatMiniProgram) {
      console.log('@542', this.isBackNavigation)
      this.clearCampaigns()
      if (this.isBackNavigation) {
        const { resultCount, isEndReached, isCampaignUpdating,
          titleCustomRefs, customFilterOptionLabels, customAttributeTags, campaignSettings, searchKey
        } = this.$store.getters['app/getlastCampaignListState']
        this.resultCount = resultCount
        this.isEndReached = isEndReached
        this.isCampaignUpdating = isCampaignUpdating
        this.titleCustomRefs = titleCustomRefs
        this.customFilterOptionLabels = customFilterOptionLabels
        this.customAttributeTags = customAttributeTags
        this.campaignSettings = campaignSettings
        this.searchKey = searchKey
      }

      if (this.isBackNavigation) {
        const filterValue = this.$store.getters['app/getFilterValue'](this.filterName)
        console.log('@491b, collected filter value', this.filterName, filterValue)
        if (filterValue) {
          this.filters = filterValue
        }
      }

      if (!this.categories || this.categories.length === 0) {
        this.initCategory()
      }

      if (!this.brands || this.brands.length === 0) {
        this.initBrands()
      }

      if (!this.locations || this.locations.length === 0) {
        this.initLocations()
      }

      if (!this.offerTypeLabels || this.offerTypeLabels.length === 0) {
        this.initOfferTypeLabels()
      }

      console.log('@471b', this.customFilterOptionLabels, this.customAttributeTags)
      console.log('@478x', this.resultCount)

      if (!this.isHomePage()) {
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'deals|list')
      } else {
        sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'home|home')
      }
      if (!this.isBackNavigation) {
        this.getCustomAttributeTags()
        this.getCampaignsSettings()

        if (this.isHomePage()) {
          console.log('@579c, get top offer campaigns')
          this.getTopOfferCampaigns()
        } else {
          console.log('@579d, get all campaigns')

          this.getCampaigns()
        }
      }
    } else {
      sendAdobePageViewAnalytics(this.$route, this.$i18n.locale, this.$store, 'home|home')
    }

    console.log('home mounted')

    setTimeout(() => { this.$store.commit('app/couponItemLoadingFinish') }, 3000)

    const isAddToHomeScreenShowed = this.$store.getters['app/getIsAddToHomeScreenShowed']
    const lastTimestamp = this.$store.getters['local/getAddToHomeScreenTimestamp']
    let isGotCookiePolicy = this.$store.getters['local/getIsGotCookiePolicy']
    const nowTimestamp = Date.parse(new Date())
    const urlParams = new URLSearchParams(window.location.search)
    const debug = urlParams.get('debug')
    let interval = 7 * 24 * 60 * 60 * 1000
    if (debug) {
      interval = 6 * 1000
      isGotCookiePolicy = false
    }
    if (!isAddToHomeScreenShowed && nowTimestamp - lastTimestamp > interval) {
      this.isShowAddToHomeScreen = false
      this.$store.commit('app/addToHomeScreenShowed')
    }
    const isCookiePolicyShowed = this.$store.getters['app/getCookiePolicyShowed']
    if (!isCookiePolicyShowed && !isGotCookiePolicy && !process.env.VUE_APP_ONE_TRUST_COOKIE_ID) {
      this.isShowCookiePolicy = true
    }
    // const firstHomeAlertShowed = this.$cookie.get('firstHomeAlertShowed')
    // if (!firstHomeAlertShowed) {
    //   this.$store.commit('app/setIsHomeAlertShow', true)
    // }
    this.$store.commit('app/setNavIsHomePage', true)
    // this.$store.commit('app/setNeedToShowAlert', true)
    setTimeout(() => { this.isBackNavigation = false }, 0)
  },
  methods: {
    inputChanged (filterValue) {
      console.log('@491@478pp', this.filterName, JSON.stringify(filterValue))
      this.$store.commit('app/setFilterValue', { filterName: this.filterName, filterValue })

      this.updateFilteredResultCount({ subMenu: 'location', filterValue: filterValue }, true)
    },

    async getCampaignsSettings () {
      const payload = {}
      const response = await axios.get(`/campaigns/settings`, payload)
      if (response && response.data) {
        // this.campaignSettings = { ...response.data }

        this.campaignSettings = {
          'category': response.data.show_campaign_category_filter,
          'brand': response.data.show_brand_filter,
          'offerType': response.data.show_offer_type_label_filter,
          'location': response.data.show_location_filter,
          'outOfStock': response.data.hide_out_of_stock_campaign
        }
      }
    },
    isShowVisualizeFilter (isShow) {
      this.hasCategoryCarousel = isShow
    },
    toggleFilterSideBar (shown) {
      this.filterSideBarShown = shown
    },
    moveLeft () {
      if (this.scrollIndex > 0) {
        this.scrollIndex -= 1
      }
    },
    moveRight () {
      if (this.myWalletSectionLength - this.scrollIndex > 3) {
        this.scrollIndex += 1
      }
    },
    toggleTncDialog (tncItemId) {
      this.tncItemId = tncItemId
      this.showTnc = !this.showTnc
    },
    getDefaultInitQueryString () {
      const params = this.getQueryParams()
      delete params.is_redirection_campaign

      const queryString = convertToQueryString({ ...params, sort: 'display_priority' })
      return queryString
    },
    async initCategory () {
      try {
        // const queryString = convertToQueryString({ ...params, sort: 'display_priority' })
        // const response = await axios.get(`/v2/campaign_categories?${queryString}`)
        const response = await axios.get(`/v2/campaign_categories?${this.getDefaultInitQueryString()}`)
        // console.log('@555', response.data)
        this.$store.commit('campaigns/initCategories', response.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async initBrands () {
      try {
        const response = await axios.get(`/v2/brands?${this.getDefaultInitQueryString()}`)
        this.$store.commit('campaigns/initBrands', response.data)
        // console.log('@555b', response.data)
      } catch (error) {
        console.log('error', error)
      }
    },

    async initOfferTypeLabels () {
      try {
        const response = await axios.get(`/v2/offer_type_labels?${this.getDefaultInitQueryString()}`)
        this.$store.commit('campaigns/initOfferTypeLabels', response.data)
        // console.log('@555c', response.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async initLocations () {
      try {
        const response = await axios.get(`/v2/locations?${this.getDefaultInitQueryString()}`)
        console.log('@555d', response.data)
        this.$store.commit('campaigns/initLocations', response.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async getTopOfferCampaigns () {
      console.log('@654', this.filters)
      const params = {
        ...this.getQueryParams(),
        lang: this.$i18n.locale,
        show_in_wechat_homepage: true
        // 'page[size]': 20 // TODO: lift the limittion?
        // TODO: add for wechat homepage
      }

      const cancelTokenSource = axios.CancelToken.source()
      const sorting = 'featured'

      let results = []
      let count = 0
      try {
        console.log('@547x', params);
        ({ count, results } = await fetchCampaigns(params, sorting, cancelTokenSource.token))

        if (!count) {
          const params2 = { ...params, 'page[size]': 8,
            random_sort: true,
            show_in_wechat_homepage: false
          }

          console.log('@547x2', params2);
          ({ count, results } = await fetchCampaigns(params2, sorting, cancelTokenSource.token))

          if (results.length) {
            // show is_featured first
            results = results.sort((a, b) => {
              if (a.is_featured) return -1
              return b.id - a.id
            })
          }
        }
      } catch (error) {
        console.log('error', error)
      }

      console.log('@677', count, results.map(item => {
        return { id: item.id, is_featured: item.is_featured }
      }
      ))

      if (count) {
        this.$store.commit('campaigns/initCampaigns', [...results])
      }

      this.isEndReached = true
      this.isCampaignUpdating = false
    },
    async getCampaigns (changeKeyword = null, isFilter = null) {
      if (this.isEndReached) {
        // console.log('end reached')
        return
      }
      this.isCampaignUpdating = true

      let { sorting } = this.filters
      sorting = sorting || 'featured'

      console.log('@654', this.filters)
      const params = {
        ...this.getQueryParams(this.filters),
        lang: this.$i18n.locale,
        'page[number]': this.pageNumber,
        'page[size]': this.pageSize,
        search_campaign: this.searchKey ? this.searchKey.toLowerCase() : '',
        sort: sorting === 'recommended' ? 'search_order' : sorting === 'featured' ? ['is_out_of_stock', '-is_featured', 'english_name', '-id'] : sorting
      }

      const cancelTokenSource = axios.CancelToken.source()

      let results = []
      let count = 0
      try {
        console.log('@547x', params);
        ({ count, results } = await fetchCampaigns(params, sorting, cancelTokenSource.token))
      } catch (error) {
        console.log('error', error)
      }

      console.log('@478p, campaigns results', results)

      this.cancelTokenSource = null

      if (count) {
        this.resultCount = count

        if (results.length < this.pageSize) {
          this.isEndReached = true
        }

        const storeCampaigns = this.$store.getters['campaigns/campaigns'] || []
        this.$store.commit('campaigns/initCampaigns', [...storeCampaigns, ...results])
      } else {
        if (this.searchKey) {
          this.resultCount = 0
        }
        this.isEndReached = true
      }
      this.isCampaignUpdating = false

      if (changeKeyword) {
        // track generic_offer_campaign_search_result pv
        sendPageViewAnalytics(this.$route, this.$i18n.locale, this.$store, { 'pageName': 'generic_offer_campaignlist_search_result', 'search_term': this.searchKey, 'search_result_count': count })
        sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'deals|keyword search', { 'search': { 'deals_term': this.searchKey, 'deals_result': `${count}` }})
        // if (count) {
        //   // track generic_offer_campaign_search_success event
        //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'generic_offer_campaign_search_success', 'event')
        // } else {
        //   // track generic_offer_campaign_search_unsuccess event
        //   sendManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'generic_offer_campaign_search_unsuccess', 'event')
        // }
      }
      if (isFilter) {
        const filterValue = this.filters
        const { brandIdMaps, offerTypeLabelIdMaps } = this.filterOptions

        if (filterValue.subMenu === 'location') {
          let goodie_filter_level1 = ''
          let level1Count = 0
          if (filterValue.location.L_AREA?.length) {
            level1Count = level1Count + filterValue.location.L_AREA?.length
            filterValue.location.L_AREA.sort(function (a, b) { return a - b }).forEach((item, index) => {
              if (index === 0) {
                goodie_filter_level1 += `A:${item}`
              } else {
                goodie_filter_level1 += `+A:${item}`
              }
            })
          }
          if (filterValue.location.L_DISTRICT?.length) {
            level1Count = level1Count + filterValue.location.L_DISTRICT?.length
            if (goodie_filter_level1) {
              goodie_filter_level1 += ','
            }
            filterValue.location.L_DISTRICT.sort(function (a, b) { return a - b }).forEach((item, index) => {
              if (index === 0) {
                goodie_filter_level1 += `D:${item}`
              } else {
                goodie_filter_level1 += `+D:${item}`
              }
            })
          }
          sendAdobeManualAnalytics(
            this.$route,
            this.$i18n.locale,
            this.$store,
            level1Count === 1 ? 'deals|filter location|single' : level1Count === 0 ? 'deals|filter location|clear' : 'deals|filter location|multi',
            { 'filter': { 'deals_filter_level1': goodie_filter_level1, 'deals_result': `${count}` }}
          )
        } else if (filterValue.subMenu === 'category' && filterValue.selectedCategory) {
          if (filterValue.selectedCategory.id !== 0) {
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'deals|filter category|single', { 'filter': { 'deals_filter_level1': `C:${filterValue.selectedCategory.id}`, 'deals_result': `${count}` }})
          } else {
            sendAdobeManualAnalytics(this.$route, this.$i18n.locale, this.$store, 'deals|filter category|all', { 'filter': { 'goodie_filter_level1': '', 'goodie_result': `${count}` }})
          }
        } else {
          let goodie_filter_level1 = ''
          let brandIds = []
          const categoryIds = []
          const tagIds = []
          let offerTypeLabelIds = []
          for (const [key, value] of Object.entries(filterValue)) {
            if (key === 'brand') {
              if (filterValue.brand?.length) {
                if (goodie_filter_level1) {
                  goodie_filter_level1 += ','
                }
                brandIds = filterValue.brand.map((item) => brandIdMaps[item] || '')
              }
              continue
            } else if (key === 'category') {
              if (filterValue.category) {
                for (const [categoryId, itemTagIds] of Object.entries(filterValue.category)) {
                  categoryIds.push(categoryId)
                  if (itemTagIds && itemTagIds.length) {
                    tagIds.push(...itemTagIds)
                  }
                }
              }
              continue
            } else if (key === 'offerType') {
              if (value && value.length) {
                offerTypeLabelIds = value.map((item) => offerTypeLabelIdMaps[item] || '')
              }
              continue
            } else {
              if (['creditRange', 'categorySelectedIndex', 'location', 'noExpiryDate', 'offerType', 'onlySortChange', 'searchKey', 'selectedCategory', 'subMenu', 'visualize'].includes(key)) {
                continue
              }
              if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
                tagIds.push(...Object.keys(value).map((item) => parseInt(item)))
              }
            }
          }
          let level1Count = 0
          if (brandIds && brandIds.length) {
            level1Count = level1Count + brandIds.length
            goodie_filter_level1 += brandIds.sort(function (a, b) { return a - b }).map((item) => `B:${item}`).join('+')
          }
          if (categoryIds && categoryIds.length) {
            level1Count = level1Count + categoryIds.length
            if (goodie_filter_level1) {
              goodie_filter_level1 += ','
            }
            goodie_filter_level1 += categoryIds.sort(function (a, b) { return a - b }).map((item) => `C:${item}`).join('+')
          }
          if (offerTypeLabelIds && offerTypeLabelIds.length) {
            level1Count = level1Count + offerTypeLabelIds.length
            if (goodie_filter_level1) {
              goodie_filter_level1 += ','
            }
            goodie_filter_level1 += offerTypeLabelIds.sort(function (a, b) { return a - b }).map((item) => `O:${item}`).join('+')
          }
          if (tagIds && tagIds.length) {
            level1Count = level1Count + tagIds.length
            if (goodie_filter_level1) {
              goodie_filter_level1 += ','
            }
            goodie_filter_level1 += tagIds.sort(function (a, b) { return a - b }).map((item) => `T:${item}`).join('+')
          }
          sendAdobeManualAnalytics(
            this.$route,
            this.$i18n.locale,
            this.$store,
            level1Count === 1 ? 'deals|filters|single' : level1Count === 0 ? 'deals|filters|clear' : 'deals|filters|multi',
            { 'filter': { 'deals_filter_level1': goodie_filter_level1, 'deals_result': `${count}` }}
          )
        }
      }
      return
    },
    async updateFilteredResultCount (params) {
      const { filterValue, subMenu } = params
      console.log('@1073@478j', subMenu, filterValue)

      if (!['category', 'brand', 'offerType', 'location', 'searchKey'].includes(subMenu)) {
        // this.filterResultCount = {}
        return
      }
      const { [subMenu]: options } = this.filterOptions
      const resultCount = {}
      // console.log('@704a@478', JSON.stringify(options))
      /*
      if (subMenu === 'location') {
        resultCount[LOCATION_AREA_ID] = { 'all': 1 }
        resultCount[LOCATION_DISTRICT_ID] = { 'all': 1 }
      }
      */

      if (subMenu === 'category' || subMenu === 'location') {
        options.forEach(category => {
          const result = { 'all': 1 }
          let { attribute_tags: attributeTags } = category
          attributeTags = attributeTags || []

          attributeTags.map(tag => { result[tag.id] = 1 })
          console.log('@478xx', attributeTags, result)
          resultCount[category.id] = result
        })
      } else {
        options.forEach(option => {
          resultCount[option] = 1
        })
      }

      console.log('@478pp, filterResultCount', JSON.stringify(resultCount))
      this.filterResultCount = { ...this.filterResultCount, [subMenu]: resultCount }
      console.log('@1073@478j filterResultCount', this.filterResultCount)

      if (this.filterCancelTokenSource) {
        this.filterCancelTokenSource.cancel()
      }
      const filters = { ...filterValue }
      delete filters[subMenu]
      console.log('@1073@478j filters', filters)

      const queryParams = this.getQueryParams(filters)
      console.log('@673', queryParams)
      /*
      if (subMenu !== 'location' && (!queryParams || Object.keys(queryParams).length === 0)) {
        return
      }
      */

      const fetchedResultCount = {}

      if (subMenu !== 'location' && !queryParams) {
        return
      }

      let data = []

      if (this.cachedResponse[subMenu] &&
        this.cachedQuery[subMenu] === stringify(queryParams)) {
        console.log('@861, cache hit')
        data = this.cachedResponse[subMenu]
      } else {
        this.cachedQuery[subMenu] = stringify(queryParams)
        const params = {
          sort: 'display_priority',
          'page[number]': 1,
          'channel': getChannelName(),
          ...queryParams
        }

        if (subMenu === 'category') {
          delete params.is_redirection_campaign
        }

        const queryString = new URLSearchParams(params).toString()

        // let data = []
        const urlRefs = {
          category: '/v2/campaign_categories',
          brand: '/v2/brands',
          offerType: '/v2/offer_type_labels',
          location: '/v2/locations'
        }
        const { [subMenu]: urlPath } = urlRefs
        console.log('@478@urlRefs', urlRefs, urlPath, queryString)
        const filterCancelTokenSource = axios.CancelToken.source()
        try {
          const url = `${urlPath}?${queryString}`;
          ({ data } = await axios.get(url, { timeout: 5000, cancelToken: filterCancelTokenSource.token }))
        } catch (error) {
          console.log('@478, error', urlPath, error)
        }

        console.log('@478@790', urlPath, queryString, data, this.filterResultCount)
      }

      if (subMenu === 'category') {
        data.forEach(category => {
          const tagsResultCount = { 'all': category.number_of_campaigns }
          let { attribute_tags: attributeTags } = category
          attributeTags = attributeTags || []
          attributeTags.forEach(({ id: tagId, number_of_campaigns: count }) => { tagsResultCount[tagId] = count })
          fetchedResultCount[category.id] = tagsResultCount
        })
      }

      if (data && data.length) {
        this.cachedResponse[subMenu] = [...data]
      }

      if (subMenu === 'location') {
        Object.assign(fetchedResultCount,
          this.convertLocationResultCount(data, filterValue))

        this.removeHiddenLocationDistricts(filterValue)
        console.log('@815', filterValue)
      } else {
        data.forEach(option => {
          // console.log('@758', option.id, option.name, option.number_of_campaigns)
          fetchedResultCount[option.name] = option.number_of_campaigns
        })
      }

      this.filterCancelTokenSource = null
      this.filterResultCount = { ...this.filterResultCount, [subMenu]: fetchedResultCount }

      console.log('@478y, filterResultCount@b', JSON.stringify(this.filterResultCount))
    },
    handleScroll () {
      if (!this.isWechatMiniProgram || !this.isExplorePage()) {
        return
      }

      const htmlEl = window.$('html')
      const bodyEl = window.$('body')
      if (htmlEl.hasClass('no-scroll')) {
        return
      }

      const scrollTop = htmlEl.scrollTop() || bodyEl.scrollTop()

      var bottom = htmlEl.height() - (scrollTop + window.$(window).height())
      if (bottom < 400 && !this.isCampaignUpdating) {
        this.getCampaigns()
      }
    },
    cookiePolicyClose () {
      this.isShowCookiePolicy = false
      this.$store.commit('app/cookiePolicyShowed')
      this.$store.commit('local/gotCookiePolicy')
    },
    addToHomeScreenClose () {
      this.isShowAddToHomeScreen = false
      this.$store.commit('local/setAddToHomeScreenTimestamp', Date.parse(new Date()))
    },
    clearCampaigns () {
      if (!this.isBackNavigation) {
        this.$store.commit('campaigns/clearCampaigns')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/coupon-tab-menu.scss';
@import '@/assets/coupon-item.scss';

.is-vue-desktop {
  .home-main-container {

    .home-section-header {
      margin-left: 30px;
      margin-right: 30px;
      .home-section-link{
        font-size: 24px;
      }
      .home-section-link::after{
        top: 8.5px;
      }
    }
  }

  .home-coupon-container {
    .coupon-list-container {

      .coupon-list {
        padding-left: 20px;
        padding-right: 20px;
        overflow-x: unset;
        overflow-y: unset;
      }
    }
    .arrow-btn.active {
      display: flex;
    }
  }

  .discover-offer-container {
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    height: 168px;

    .discover-offer-title {
      font-size: 30px;
      line-height: 41px;
      margin-bottom: 24px;
    }

    .discover-offer-content {
      font-size: 15px;
      line-height: 23px;
    }
  }

  .filter-container {
    padding-left: 14px;
    padding-right: 14px;
  }

  .result-count {
    font-size: 15px;
    line-height: 23px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
  }

  .goodie-bag-container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .campaign-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .carousel-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 40px;
    padding-bottom: 72px
  }
}

.is-vue-mobile .home-main-container.is-not-login {
  //min-height: 0px;

  .home-section-header.explore-more {
    //margin-top: -8px;
  }
}

.home-main-container .home-section-header.explore-more {
  margin: 0;
  padding: 24px 16px 0px;
  background-color: #FFFFFF;
}

.is-vue-desktop .home-main-container.is-not-login {
  min-height: 200px;
}

.view-all-offers-button-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

  .view-all-offers-button {
    padding: 10px 30px;
    gap: 8px;

    background: #E2002C;

    border-radius: 50px;
    cursor: pointer;

    .view-all-offers-text {
      font-family: 'Open Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22px;
      color: #FFFFFF;
      position: relative;
      padding-right: 14px;

      &::after {
          content: "";
          position: absolute;
          right: 2px;
          top: 8px;
          width: 8px;
          height: 8px;
          border: 2px solid #FFFFFF;
          border-top: 0;
          border-left: 0;
          transform: rotate(-45deg);
          color: #fff;
        }
    }
    /* Inside auto layout */
  }
}

.home-main-container {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #F3F4F7;

  .home-section-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 16px;
    margin-right: 16px;
    margin-top: 24px;
    margin-bottom: 20px;
    align-items: flex-end;

    .home-section-label {
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      color: #000000;
    }

    .home-section-link {
      position: relative;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: #E2002C;
      padding-right: 14px;

      &::after {
        content: "";
        position: absolute;
        right: 2px;
        top: 8px;
        width: 8px;
        height: 8px;
        border: 2px solid #E2002C;
        border-top: 0;
        border-left: 0;
        transform: rotate(-45deg);
      }

    }
  }
}

.discover-offer-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 32px;
  margin-bottom: 16px;
  padding-left: 16px;
  padding-right: 16px;

  .discover-offer-title {
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 8px;
    text-align: center;
  }

  .discover-offer-content {
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    color: #E2002C;
    text-decoration: underline;
    cursor: pointer;
    text-align: center;
  }
}
.filter-container.hasCategoryCarousel{
  top: calc(-147px - var(--sat));
}

.filter-container {
  background: #F5F5F5;
  z-index: 2;
  position: sticky;
  top: -8px;

  &.filter-bar-shown {
    //this will block the z-index of filter overlay!!!
    z-index: 900; //filter container
  }
}

.result-count{
  padding-left: 21px;
  padding-right: 21px;
  font-size: 12px;
  line-height: 20px;
  color: #8B8B8B;
  margin-bottom: 13px;
}

.carousel-container {
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 10px;
  padding-top: 24px;
  padding-bottom: 38px
}

.goodie-bag-container {
  padding-left: 16px;
  padding-right: 16px;
  margin-bottom: -20px;
}

.home-coupon-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .coupon-list-container {
    overflow: hidden;
    margin-top: -10px;

    .coupon-list {
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      transition: transform 300ms ease-in-out;
      padding-top: 10px;
      padding-left: 6px;
      padding-right: 6px;
      display: flex;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */

      &::-webkit-scrollbar {
        display: none;
      }

      .home-coupon-item {
        flex: 0 0 auto;
      }
    }
  }

  .arrow-btn {
    cursor: pointer;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 164px;
    right: -30px;;
    background: #FFFFFF;
    border-radius: 30px;
    // display: flex;
    display: none;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

    .arrow-icon {
      margin-right: -1px;
    }

    &.active {
      z-index: 1;
      opacity: 1;
    }
    &.left {
      right: unset;
      left: -30px;
      transform: rotate(180deg);

      .arrow-icon {
        margin-right: 0;
        margin-left: -1px;
      }

    }
  }

}

.campaign-container {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-left: 12px;
  padding-right: 12px;
  margin-bottom: -16px;
  z-index: 1
  //justify-content: space-evenly;
}

.empty-result {
  margin-top: 24px;
  margin-bottom: 59px;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .empty-result-title {
    font-size: 14px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 16px;
  }
}

.bottom-alert{
  position: fixed;
  bottom: 0px;
  width: 100%;
  left: 0px;
  z-index: 10;
}

.is-vue-mobile{
  .coupon-merchant-text-content{
    width: calc(100% - 52px);
  }

}

.is-vue-desktop {
  .coupon-merchant-text{
    width: 150px;
  }
}

.mini .bottom-alert{
  bottom: calc(66px + var(--sab));
}

</style>

<style lang="scss">
.is-vue-mobile{
  .document-explore {
    .back-top-placeholder{
      height: 32px;
    }
    .main-container .el-backtop{
      margin-top: 0px;
    }
  }
}
</style>
